import styled from "styled-components";
import { Button, CircularProgress, OutlinedInput, Snackbar } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { DARK_BLUE, LIGHT_GREY, LIGHT_GREY0, WHITE } from "../../../assets/colors";
import { useAppDispatch } from "../../../app/store";
import ModalTopbar from "../../../components/navigation/modalTopbar";
import { selectSeason, selectUser } from "../../../slices/userSlice";
import { useSelector } from "react-redux";
import { searchBarStyles, sportsAiGlobalStyles } from "../../../shared/globalStyle";
import CheckIcon from '@mui/icons-material/Check';
import { POSITIONS } from "../../../models/constant/playerPositions";
import { playerApi } from "../../../api/playerApi";
import { useDropzone } from "react-dropzone";
import { getTeamPlayers, getUserPlayerInfos } from "../../../slices/playerSlice";
import { selectTeamId } from "../../../slices/teamSlice";
import { Alert } from "@mui/material";

export const CreatePlayerModal = ({ setIsCreateGameComp, player, calledFromGameModal, setCurrentPage }) => {
    const classes = sportsAiGlobalStyles();
    const searchBarClass = searchBarStyles();
    const dispatch = useAppDispatch();
    const user = useSelector(selectUser);
    const season = useSelector(selectSeason);
    const teamId = useSelector(selectTeamId);

    const [profileImage, setProfileImage] = useState<File | null>(null);
    const [profileUrl, setProfileUrl] = useState<string | null>(null);
    const [firstName, setFirstName] = useState(player?.firstName);
    const [lastName, setLastName] = useState(player?.lastName);
    const [email, setEmail] = useState(player?.email);
    const [number, setNumber] = useState(player?.number);
    const [selectedDate, setSelectedDate] = useState(player?.birthDate);
    const [position, setPosition] = useState(player?.position);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [loading, setLoading] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState<string | null>(null);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const [formErrors, setFormErrors] = useState({
        firstName: "",
        lastName: "",
        email: "",
        number: "",
        birthDate: "",
        position: ""
    });
    useEffect(() => {
      if (notificationMessage) {
        // Only open Snackbar when notificationMessage is set
        setOpenSnackbar(true);
      }
    }, [notificationMessage]);

    const validateInput = () => {
      const errors: any = {};
      let isValid = true;
      let message = "";
      
      if (!position) {
        errors.position = "Position is required";
        isValid = false;
        message = "Position is required";
    }
      if (!selectedDate) {
        errors.birthDate = "Birthdate is required";
        isValid = false;
        message = "Birthdate is required";
    }
      if (!number) {
        errors.number = "Number is required";
        isValid = false;
        message = "Number is required";
    }
      if (!email) {
        errors.email = "Email is required";
        isValid = false;
        message = "Email is required";
    } else if (!emailRegex.test(email)) {
        errors.email = "Invalid email format";
        isValid = false;
        message = "Invalid email format";
    }
      if (!lastName) {
        errors.lastName = "Last name is required";
        isValid = false;
        message = "Last name is required";
    }
      if (!firstName) {
          errors.firstName = "First name is required";
          isValid = false;
          message = "First name is required";
      }

      setFormErrors(errors);
      return { isValid, message };
    };

    const handleCloseNotification = () => {
      setOpenSnackbar(false);
      setNotificationMessage(null);
  };

    const submitPlayer = (dispatch) => {
      const { isValid, message } = validateInput();
      if (!isValid) {
          setNotificationMessage(message);
          setOpenSnackbar(true);
          return;
      }
        setLoading(true); 
        const formData = new FormData();
        if (player?.id) {
            formData.append("id", player?.id.toString());
        }
        formData.append("profileFile", profileImage!);
        formData.append("firstName", firstName);
        formData.append("lastName", lastName);
        formData.append("email", email);
        formData.append("number", number);
        formData.append("birthDate", selectedDate);
        formData.append("season", season!.toString());
        formData.append("position", position);
        formData.append("teamId", "0");
        playerApi.postPlayer(formData).then((response) => {
          if (response.status === 201) {
              setNotificationMessage("Player added successfully!");
              if (teamId) {
                dispatch(getTeamPlayers({teamId, season})).finally(() => {
                  dispatch(getUserPlayerInfos({teamId, season})).finally(() => {
                    setLoading(false); 
                    if (!calledFromGameModal){
                      setIsCreateGameComp(false);
                    } else {
                      setCurrentPage(4);
                    }
                  });
              });             
            }
          }
        }).catch((error) => {
          setLoading(false);
          const errorMsg = error.response?.data?.error?.message || "Failed to add player";
          setNotificationMessage(errorMsg);
        });
    };

    const onDrop = useCallback((acceptedFiles: File[]) => {
      const file = acceptedFiles[0];
      setProfileImage(file);
      setProfileUrl(URL.createObjectURL(file));
    }, []);

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    return (
        <Wrapper>
            {calledFromGameModal ?  <></> : <ModalTopbar Action={"Create Player"} onClose={() => { setIsCreateGameComp(false) }}/>}
            <div {...getRootProps()}>
            <input {...getInputProps()}/>
            <ProfileEntry>
                <ProfileText>Profile Picture</ProfileText>
                <ProfileIconWrapper>
                  <ProfileIcon src={profileUrl || user?.profile_url} alt="Profile" />
                </ProfileIconWrapper>
                <UploadButtonWrapper>
                    <UploadButton 
                        className={classes.fullBlueButton}
                        style={{ textTransform: "none" }}
                        onClick={() => {}}
                    >
                        Upload New
                    </UploadButton>
                    <UploadHintText>Drop file or click to upload</UploadHintText>               
                </UploadButtonWrapper>
            </ProfileEntry>
            </div>
            <ProfileEntry>
                <ProfileText>First Name</ProfileText>
                <ProfileInputWrapper>
                    <OutlinedInput         
                        classes={searchBarClass}
                        style={{ width: "10rem", height: "2.2rem", borderRadius: "0.5rem", padding: "0.5rem" }}
                        value={firstName}
                        onChange={(event) => setFirstName(event.target.value)}
                    /> {formErrors.firstName && (
                      <span style={{ color: 'red' }}>{'*'}</span>
                    )}
                </ProfileInputWrapper>       
            </ProfileEntry>
            <ProfileEntry>
                <ProfileText>Last Name</ProfileText>
                <ProfileInputWrapper>
                    <OutlinedInput         
                        classes={searchBarClass}
                        style={{ width: "10rem", height: "2.2rem", borderRadius: "0.5rem", padding: "0.5rem" }}
                        value={lastName}
                        onChange={(event) => setLastName(event.target.value)}
                    />{formErrors.lastName && (
                      <span style={{ color: 'red' }}>{'*'}</span>)}
                </ProfileInputWrapper>       
            </ProfileEntry>
            <ProfileEntry>
                <ProfileText>Birth Date</ProfileText>
                <ProfileInputWrapper>
                    <StyledDateInput
                        style={{ marginLeft: '1.9rem' }}
                        type="date"
                        id="dateInput"
                        value={selectedDate}
                        onChange={(event) => setSelectedDate(event.target.value)}
                    />{formErrors.birthDate && (
                      <span style={{ color: 'red' }}>{'*'}</span>)}
                </ProfileInputWrapper>       
            </ProfileEntry>
            <ProfileEntry>
                <ProfileText>Email Address</ProfileText>
                <ProfileInputWrapper>
                    <OutlinedInput         
                        classes={searchBarClass}
                        style={{ width: "15rem", height: "2.2rem", borderRadius: "0.5rem", padding: "0rem" }}
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                    />{formErrors.email && (
                      <span style={{ color: 'red' }}>{'*'}</span>)}
                </ProfileInputWrapper>       
            </ProfileEntry>
            <ProfileEntry>
                <ProfileText>Number</ProfileText>
                <ProfileInputWrapper>
                    <StyledNumberInput
                        style={{ marginLeft: '0rem' }}
                        type="number"
                        id="numberInput"
                        value={number}
                        onChange={(event) => setNumber(event.target.value)}
                    />{formErrors.number && (
                      <span style={{ color: 'red' }}>{'*'}</span>)}
                </ProfileInputWrapper>
                <ProfileText style={{ marginLeft: '2rem' }}>Position</ProfileText>
                <ProfileInputWrapper>
                    <StyledSelect
                        value={position}
                        onChange={(event) => setPosition(event.target.value)}
                    >
                        {
                          (['---'].concat(POSITIONS)).map((position) => {
                            return <option value={position}>{position}</option>
                          })
                        }

                    </StyledSelect>{formErrors.position && (
                      <span style={{ color: 'red' }}>{' *'}</span>)}
                </ProfileInputWrapper>
            </ProfileEntry>
            <FormButtonWrapper>
                { loading && <CircularProgress size={24} style={{ color: DARK_BLUE, marginTop: '1.3rem', marginRight: '0.5rem' }} />}
                <Button
                    className={classes.transparantButton}
                    style={{ 
                        border: `1px solid ${LIGHT_GREY0}`, 
                        textTransform: "none", 
                        width: "6rem", 
                        height: "2.4rem", 
                        borderRadius: "0.5rem", 
                        margin: "1rem 0 0 0"
                    }}
                    onClick={() => {setIsCreateGameComp(false)}}
                    disabled={loading}
                >
                    Cancel
                </Button>
                <UploadButton 
                    className={classes.fullBlueButton}
                    style={{ textTransform: "none", height: "2.4rem" }}
                    onClick={() => submitPlayer(dispatch)}
                    disabled={loading}
                >
                    <CheckIcon style={{ marginRight: '0.3rem', fontSize: '1.4rem' }}/>
                    Confirm 
                </UploadButton>
            </FormButtonWrapper>
            {/* Notification Snackbar */}
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseNotification}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseNotification} severity={notificationMessage!='Player added successfully!' ? "error" : "success"}>
                {notificationMessage !== 'Player added successfully!' ? (
                  <>
                    <div>{formErrors.firstName}<br /></div>
                    <div>{formErrors.lastName}<br /></div>
                    <div>{formErrors.email}<br /></div>
                    <div>{formErrors.birthDate}<br /></div>
                    <div>{formErrors.number}<br /></div>
                    <div>{formErrors.position}<br /></div>
                  </>
                ) : (
                  <div>{notificationMessage}</div>
                )}
                </Alert>
            </Snackbar>
        </Wrapper>
    )
}

const Wrapper = styled.div` 
    border: 3px solid ${LIGHT_GREY};
    border-radius: 1rem;
    width: 35rem;
    height: auto;
    background-color: ${WHITE};
    z-index: 1000;
    top: 0;
    left: 0;
    overflow-y: auto;
    padding: 1rem;
`;  

const ProfileEntry = styled.div`
  display: flex;
  align-items: center; 
  padding: 1rem 0rem 1rem 0.5rem; 
  font-size: 1.3rem;
  border-bottom: 1px solid ${LIGHT_GREY};
`;

const ProfileText = styled.div`
  display: flex;
  align-items: flex-start;
  flex: 1;
  font-size: 1.3rem;
`;

const ProfileIconWrapper = styled.div`
  display: flex;
  justify-content: center; 
  flex: 2; 
`;

const ProfileInputWrapper = styled.div`
  display: flex;
  align-items: flex-start; 
  flex: 2; 
`;

const StyledDateInput = styled.input`
  width: 10rem;
  height: 2.2rem;
  border-radius: 0.5rem;
  border: 1px solid ${LIGHT_GREY0};
  padding: 0rem 0.5rem 0rem 0.5rem;
  font-size: 1rem;
  font-family: "Karla", sans-serif;
  background-color: ${WHITE};
  
  &:focus {
    outline: none;
    border-color: ${DARK_BLUE};
    box-shadow: 0 0 5px ${DARK_BLUE};
  }
`;

const StyledNumberInput = styled.input`
  width: 3rem;
  height: 2.2rem;
  border-radius: 0.5rem;
  border: 1px solid ${LIGHT_GREY0};
  font-size: 1rem;
  font-family: "Karla", sans-serif;
  background-color: ${WHITE};
  padding: 0rem 0.5rem 0rem 0.5rem;
  &:focus {
    outline: none;
    border-color: ${DARK_BLUE};
    box-shadow: 0 0 5px ${DARK_BLUE};
  }
`;

const StyledSelect = styled.select`
  width: 8rem;
  height: 2.2rem;
  border-radius: 0.5rem;
  border: 1px solid ${LIGHT_GREY0};
  font-size: 1rem;
  font-family: "Karla", sans-serif;
  background-color: ${WHITE};
  padding: 0rem 0.5rem 0rem 0.5rem;

  &:focus {
    outline: none;
    border-color: ${DARK_BLUE};
    box-shadow: 0 0 5px ${DARK_BLUE};
  }

  option {
    background-color: ${WHITE}; /* Option background color */
    color: ${DARK_BLUE}; /* Option text color */
  }
`;

const ProfileIcon = styled.img`
  height: 4rem;
  width: 4rem;
  background-color: ${LIGHT_GREY};
  border-radius: 100%;
`;

const UploadButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end; 
  flex: 1; 
  padding-right: 1rem;
`;

const UploadButton = styled(Button)`
  height: 2.2rem;
  padding: 0rem 0.8rem 0rem 0.8rem;
  margin-bottom: 0.2rem;
`;

const UploadHintText = styled.div`
  font-size: 0.7rem;
  color: ${LIGHT_GREY0};
`;

const FormButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  padding-top: 1rem;
`;
